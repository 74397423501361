import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import Content from "../components/Content"

const CreditsPage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <>
      <SEO 
        title={frontmatter.seo.title}
        description={frontmatter.seo.description}
        image={frontmatter.seo.image}
      />
      <Layout>
        <Content
          title={frontmatter.title}
          content={frontmatter.content}
          lang={frontmatter.lang}
          isCredits />
      </Layout>
    </>
  )
}

export const creditsQuery = graphql`
  query creditsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        lang
        seo {
          title
          description
          image {
            publicURL
            relativePath
          }
        }
        title
        content
      }
    }
  }
`

export default CreditsPage
