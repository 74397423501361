import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Markdown from "../Markdown"

import "./Content.scss"
import { TitleAppFr, TitleAppEn } from "../Icons"

const Content = ({ title, content, lang, isCredits}) => {
  const appTitle = (lang === "fr")? <TitleAppFr /> : <TitleAppEn />
  const year = 2020

  return (
    <section className="content">
      <div className="content__container">
        <h1 className="content__title">{title}</h1>
        <div className="content__tag">{appTitle}</div>
        {(isCredits)? 
          <div className="content__credits">&copy; Orchestre Métropolitain {year}</div>
        : null}
        <div className="content__content">
          <Markdown>{content}</Markdown>
          {(lang === "fr")? 
            <Link className="content__back" to="/">Retour</Link> :
            <Link className="content__back" to="/en">Back</Link> }
        </div>
      </div>
    </section>
  )
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
}

export default Content